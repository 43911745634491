import { FilterKey } from '@services/filters/types';
import { kebabToCamelCase } from '@utils/textHelpers';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const KEYS: FilterKey[] = [
  'hotSeat',
  'workload',
  'onDeck',
  'map',
  'jobs',
  'users',
  'builders',
  'inventory',
];

export const useFilterKey = () => {
  const { pathname } = useLocation();
  const [key, setKey] = useState<FilterKey>();

  useEffect(() => {
    const newKey = KEYS.find((el) => kebabToCamelCase(pathname).endsWith(el));
    setKey(newKey);
  }, [pathname]);

  return key as FilterKey;
};
