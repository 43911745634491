import { createContext, useCallback, useContext, useState } from 'react';
import {
  DropdownsParams,
  DropdownsContextData,
  DropdownsData,
  DropdownsProviderProps,
} from './types';
import { initialState } from './constants';
import { useAdditiveDropdown } from '@services/dropdowns/additive';
import { useBuildersDropdown } from '@services/dropdowns/builders';
import { useBuilderSuperintendentsDropdown } from '@services/dropdowns/builderSuperintendents';
import { useCompaniesDropdown } from '@services/dropdowns/companies';
import { useConcreteMixDropdown } from '@services/dropdowns/concreteMix';
import { useUsersDropdown } from '@services/dropdowns/users';
import { useInventoryItemsDropdown } from '@services/dropdowns/inventoryItems';
import { useLocationsDropdown } from '@services/dropdowns/locations';
import { usePourElementsDropdown } from '@services/dropdowns/pourElements';
import { usePourMethodsDropdown } from '@services/dropdowns/pourMethods';
import { useRoutesDropdown } from '@services/dropdowns/routes';
import { useSitesDropdown } from '@services/dropdowns/sites';
import { useStagesDropdown } from '@services/dropdowns/stages';
import { useSubtasksForFilesDropdown } from '@services/dropdowns/subtasksForFiles';
import { useTasksDropdown } from '@services/dropdowns/tasks';

const DropdownsContext = createContext<DropdownsContextData | Record<string, never>>({});

export const DropdownsProvider = ({ children }: DropdownsProviderProps) => {
  const [state, setState] = useState<DropdownsData>(initialState);
  const { params } = state;

  const additive = useAdditiveDropdown(params.products);
  const builders = useBuildersDropdown();
  const builderSuperintendents = useBuilderSuperintendentsDropdown(params.builderSuperintendents);
  const companies = useCompaniesDropdown(params.companies);
  const concreteMix = useConcreteMixDropdown(params.products);
  const coordinators = useUsersDropdown({
    role: ['administrator', 'project_coordinator'],
  });
  const inventoryItems = useInventoryItemsDropdown(params.inventoryItems);
  const locations = useLocationsDropdown();
  const pourElements = usePourElementsDropdown();
  const pourMethods = usePourMethodsDropdown();
  const routes = useRoutesDropdown();
  const sites = useSitesDropdown();
  const stages = useStagesDropdown();
  const subtasksForFiles = useSubtasksForFilesDropdown(params.subtasksForFiles);
  const superintendents = useUsersDropdown({ role: ['superintendent'] });
  const tasks = useTasksDropdown(params.tasks);

  const setParams = useCallback((value: Partial<DropdownsParams>) => {
    setState((prev) => ({ ...prev, params: { ...prev.params, ...value } }));
  }, []);

  return (
    <DropdownsContext.Provider
      value={{
        ...state,
        additive: {
          options: additive.data?.records || [],
          disabled: additive.isLoading || !additive.data?.records?.length,
        },
        builders: {
          options: builders.data?.records || [],
          disabled: builders.isLoading || !builders.data?.records?.length,
        },
        builderSuperintendents: {
          options: builderSuperintendents.data?.records || [],
          disabled:
            builderSuperintendents.isLoading || !builderSuperintendents.data?.records?.length,
        },
        companies: {
          options: {
            companies: companies.data?.companies || [],
            jobContacts: companies.data?.jobContacts || [],
          },
          disabled:
            companies.isLoading ||
            (!companies.data?.companies?.length && !companies.data?.jobContacts?.length),
        },
        concreteMix: {
          options: concreteMix.data?.records || [],
          disabled: concreteMix.isLoading || !concreteMix.data?.records?.length,
        },
        constructionTypes: {
          options: ['residential', 'commercial'] as const,
          disabled: false,
        },
        coordinators: {
          options: coordinators.data?.records || [],
          disabled: coordinators.isLoading || !coordinators.data?.records?.length,
        },
        inventoryItems: {
          options: inventoryItems.data?.records || [],
          disabled: inventoryItems.isLoading || !inventoryItems.data?.records?.length,
        },
        locations: {
          options: locations.data?.records || [],
          disabled: locations.isLoading || !locations.data?.records?.length,
        },
        pourElements: {
          options: pourElements.data?.records || [],
          disabled: pourElements.isLoading || !pourElements.data?.records?.length,
        },
        pourMethods: {
          options: pourMethods.data?.records || [],
          disabled: pourMethods.isLoading || !pourMethods.data?.records?.length,
        },
        rateTypes: {
          options: ['SPACING_IN_MINUTES', 'QTY_PER_HOUR'] as const,
          disabled: false,
        },
        routes: {
          options: routes.data?.records || [],
          disabled: routes.isLoading || !routes.data?.records?.length,
        },
        setParams,
        sites: {
          options: sites.data?.records || [],
          disabled: sites.isLoading || !sites.data?.records?.length,
        },
        stages: {
          options: stages.data?.records || [],
          disabled: stages.isLoading || !stages.data?.records?.length,
        },
        states: {
          options: ['construction', 'pre_construction', 'follow_up'] as const,
          disabled: false,
        },
        statuses: {
          options: ['pending', 'approved', 'rejected'] as const,
          disabled: false,
        },
        subtasksForFiles: {
          options: subtasksForFiles.data?.records || [],
          disabled: subtasksForFiles.isLoading || !subtasksForFiles.data?.records?.length,
        },
        superintendents: {
          options: superintendents.data?.records || [],
          disabled: coordinators.isLoading || !coordinators.data?.records?.length,
        },
        tasks: {
          options: tasks.data?.records || [],
          disabled: tasks.isLoading || !tasks.data?.records?.length,
        },
      }}>
      {children}
    </DropdownsContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useDropdownsContext = () => useContext(DropdownsContext);
