import { useQuery } from '@tanstack/react-query';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { useQueryParams } from '@hooks/queryParams';
import {
  InventoryLocationItemsRespSuccess,
  InventoryLocationProps,
} from '@services/inventory_new/types';
import { parseCustomItems } from '@services/inventory_new/helpers';

export const useGetInventoryRequestedLocation = ({
  preview,
  location,
  customItems,
}: InventoryLocationProps) => {
  const {
    params: { routeId },
    parseParams,
  } = useQueryParams();

  const { type, locationId, locationType } = preview;
  const [pickUpLocationId, pickUpLocationType] = [location?.locationId, location?.locationType];

  const queryParams = parseParams({
    routeId,
    locationId,
    locationType,
    pickUpLocationId,
    pickUpLocationType,
    pickUpCustomItems: parseCustomItems(customItems),
  });

  const { data, error, isFetching } = useQuery<InventoryLocationItemsRespSuccess, ApiError>({
    queryKey: [...queryKeys.inventoryRequestedLocation, queryParams],
    queryFn: () =>
      queryFetch({
        url: `routes/requested/location_inventory${queryParams}`,
      }),
    placeholderData: (previousData) => previousData as InventoryLocationItemsRespSuccess,
    enabled:
      type === 'requested' && Boolean(locationId && uuidRegEx.test(locationId) && locationType),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
