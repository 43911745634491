import { DropdownsData } from './types';

export const initialState: DropdownsData = {
  params: {
    builderSuperintendents: {},
    companies: {},
    inventoryItems: {},
    products: {},
    subtasksForFiles: {},
    tasks: {},
  },
};
