import { AppData } from './types';

export const initialState: AppData = {
  accessToken: '',
  client: '',
  uid: '',
  id: '',
  isLogged: false,
  isManager: false,
  isForeman: false,
  isCadTechnician: false,
  speciality: null,
  apiActivity: true,
  onDeckError: false,
  hotSeatError: false,
  isTutorial: false,
  isDropdownEnabled: false,
};
