import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { initialState } from './constants';
import {
  InventoryQuantitiesContextData,
  InventoryQuantitiesData,
  InventoryQuantitiesProviderProps,
} from './types';
import { useGetLocationItems } from '@services/inventory/locations/items';
import { useDropdownsContext } from '@contexts/dropdowns';

const InventoryQuantitiesContext = createContext<
  InventoryQuantitiesContextData | Record<string, never>
>({});

export const InventoryQuantitiesProvider = ({ children }: InventoryQuantitiesProviderProps) => {
  const {
    locations: { options, disabled },
  } = useDropdownsContext();

  const [state, setState] = useState<InventoryQuantitiesData>(initialState);

  const dataResp = useGetLocationItems(state.location);
  const data = dataResp.data?.records;

  const toggle = (open: boolean) => {
    setState((prev) => ({ ...prev, open }));
  };

  const setLocation = useCallback(
    (id?: string) => {
      const location = options.find((el) => el.locationId === id) || initialState.location;
      setState((prev) => ({ ...prev, location }));
    },
    [options],
  );

  useEffect(() => {
    if (!data) return;
    setState((prev) => ({ ...prev, data }));
  }, [data]);

  return (
    <InventoryQuantitiesContext.Provider
      value={{
        toggle,
        setLocation,
        isLoading: dataResp.isLoading || disabled,
        ...state,
        setState,
      }}>
      {children}
    </InventoryQuantitiesContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useInventoryQuantitiesContext = () => useContext(InventoryQuantitiesContext);
