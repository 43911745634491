import { Button, ButtonProps } from '@components/ui/button';
import { cn } from '@utils/cn';
import { ElementRef, forwardRef } from 'react';

export const NavbarButton = forwardRef<ElementRef<typeof Button>, ButtonProps>(
  ({ className, ...props }, ref) => (
    <Button
      ref={ref}
      variant="ghost"
      className={cn(
        'relative flex h-9 w-9 items-center justify-center p-0 text-neutral-400 hover:text-primary-500',
        className,
      )}
      {...props}
    />
  ),
);
