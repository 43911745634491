import { cn } from '@utils/cn';
import { Tooltip, TooltipArrowProps, TooltipContent, TooltipProvider, TooltipTrigger } from '.';
import { ReactNode } from 'react';

export const AppTooltip = ({
  children,
  context,
  variant = 'dark',
  side = 'bottom',
  align = 'center',
  disableHoverableContent = false,
  delayDuration,
  className,
  arrowProps,
  open,
  setOpen,
}: {
  children: ReactNode;
  context: ReactNode;
  variant?: 'light' | 'dark';
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'center' | 'end' | 'start';
  disableHoverableContent?: boolean;
  delayDuration?: number;
  className?: string;
  arrowProps?: TooltipArrowProps;
  open?: boolean;
  setOpen?: (value: boolean) => void;
}) => {
  return (
    <TooltipProvider disableHoverableContent={disableHoverableContent}>
      <Tooltip open={open} onOpenChange={setOpen} delayDuration={delayDuration}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent
          align={align}
          side={side}
          arrowProps={{
            className: cn(variant === 'dark' ? 'text-neutral-700' : 'text-neutral-50'),
            ...arrowProps,
          }}
          className={cn([
            'px-2.5 py-2',
            variant === 'dark'
              ? 'bg-neutral-700 text-neutral-50'
              : 'bg-neutral-50 text-neutral-700',
            className,
          ])}>
          {context}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
