import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { useQueryParams } from '@hooks/queryParams';
import { CompaniesDropdownProps, CompaniesDropdownRespSuccess } from './types';
import { useAppContext } from '@contexts/app';

export const useCompaniesDropdown = ({ jobId }: CompaniesDropdownProps) => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { parseParams } = useQueryParams();

  const queryParams = parseParams({ jobId });

  const { data, error, isFetching } = useQuery<CompaniesDropdownRespSuccess, ApiError>({
    queryKey: [...queryKeys.dropdowns.companies, queryParams],
    queryFn: () => queryFetch({ url: `dropdowns/companies${queryParams}` }),
    placeholderData: (previousData) => previousData as CompaniesDropdownRespSuccess,
    enabled: enabled && Boolean(jobId && uuidRegEx.test(jobId)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
