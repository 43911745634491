import { InventoryQuantitiesData } from './types';

export const initialState: InventoryQuantitiesData = {
  open: false,
  data: [],
  location: {
    locationId: '',
    locationType: 'job',
    locationAddress: '',
  },
};
