import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { ChevronDown } from 'lucide-react';

import { cn } from '@utils/cn';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

type CustomizedSelect = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
  prefixElement?: React.ReactNode;
  suffixElement?: React.ReactNode;
  readOnly?: boolean;
};

type CustomizedSelectItem = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
  itemTextAsChild?: boolean;
};

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  CustomizedSelect
>(({ className, children, prefixElement, suffixElement, ...props }, ref) => (
  <div
    className={cn(
      'group-[.error]:shadow-danger-b flex h-8 w-full items-center gap-2 rounded border border-neutral-300 bg-white text-s text-neutral-800 focus-within:border-primary-600 focus-within:outline focus-within:outline-2 focus-within:outline-primary-200',
      props['aria-invalid'] ? 'border-danger-500 focus-within:border-danger-500' : '',
      props.disabled ? 'border-neutral-200 bg-neutral-50 text-neutral-400' : '',
      props.readOnly ? 'border-neutral-300 text-neutral-800' : '',
      className,
    )}>
    {prefixElement}
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex w-full grow items-center justify-between px-3 py-1.5 text-start text-m leading-none text-neutral-800 ring-offset-background placeholder:text-neutral-400 focus-visible:outline-none disabled:cursor-not-allowed',
        props.disabled ? 'text-neutral-400' : '',
        props.readOnly ? 'text-neutral-800' : '',
        className,
      )}
      {...props}>
      <span className="block w-full truncate">{children}</span>
      <SelectPrimitive.Icon asChild>
        <span>
          <ChevronDown className="h-4 w-4 opacity-50" />
        </span>
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
    {suffixElement}
  </div>
));
// eslint-disable-next-line immutable/no-mutation
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'scrollbar-thin relative z-[60] max-h-[215px] min-w-[8rem] overflow-y-scroll rounded-md rounded-r-none border border-neutral-200 bg-white text-neutral-700 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className,
      )}
      position={position}
      {...props}>
      <SelectPrimitive.Viewport
        style={{ height: 'fit-content', flex: 'none' }}
        className={cn(
          'p-1.5 pr-0',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}>
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
// eslint-disable-next-line immutable/no-mutation
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
    {...props}
  />
));
// eslint-disable-next-line immutable/no-mutation
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  CustomizedSelectItem
>(({ className, children, itemTextAsChild, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex w-full cursor-default select-none items-center px-1.5 py-1.5 text-sm outline-none focus:bg-primary-200 data-[disabled]:pointer-events-none data-[state="checked"]:bg-primary-400 data-[state="checked"]:text-white data-[disabled]:opacity-50',
      className,
    )}
    {...props}>
    <SelectPrimitive.ItemText asChild={itemTextAsChild}>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
// eslint-disable-next-line immutable/no-mutation
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-neutral-100', className)}
    {...props}
  />
));
// eslint-disable-next-line immutable/no-mutation
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
};
