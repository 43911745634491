import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { SetError, handleOnSettled, useHandleFormErrors } from '@utils/formHelpers';
import { DefaultApiResp, JobInventoryItem } from '@root/globalTypes';
import { useCallback, useState } from 'react';
import { JobInventoryItemReq } from '@services/jobs/inventory/types';
import { useUpdateJobInventoryItems } from '@services/jobs/inventory/bulk/update';
import { InventoryItemsDropdown } from '@services/dropdowns/inventoryItems/types';

interface UseJobInventoryFormProps {
  successHandler: () => void;
  dialog?: boolean;
}

type JobInventoryInitialValues = Array<JobInventoryItem | InventoryItemsDropdown>;

export const useJobInventoryForm = ({ successHandler, dialog }: UseJobInventoryFormProps) => {
  const [initialValues, setInitialValues] = useState<JobInventoryInitialValues>([]);
  const handleFormErrors = useHandleFormErrors();

  const formSchema = z.object({
    items: z.array(
      z.object({
        itemId: z.string(),
        needed: z.string(),
      }),
    ),
  });

  const defaultValues = {
    items: [],
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'all',
    defaultValues,
  });

  const { fields, replace } = useFieldArray({
    control: form.control,
    name: 'items',
  });

  const resetFields = useCallback(
    (values?: JobInventoryInitialValues) => {
      if (!values) return;
      setInitialValues(values);
      replace(values.map((el) => ({ ...el, itemId: el.id, needed: el.needed.toString() })));
    },
    [replace],
  );

  const onSettled = (resp: DefaultApiResp) => {
    const handleError: SetError = (name, { type, message }) => {
      form.setError(name as keyof z.infer<typeof formSchema>, {
        type,
        message,
      });
    };
    let error: object = {};
    if (resp?.errors) {
      error = { root: resp.errors };
      if (Object.keys(resp.errors).length) {
        error = { ...resp.errors } as object;
      }
    }

    handleOnSettled<DefaultApiResp>(resp, successHandler, () =>
      handleFormErrors(handleError, Object.keys(defaultValues), error),
    );
  };

  const update = useUpdateJobInventoryItems({ callback: onSettled });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const items: JobInventoryItemReq[] = values.items
      .map(({ itemId, needed }) => ({
        globalItemId: dialog ? itemId : undefined,
        id: !dialog ? itemId : null,
        needed: Number(needed),
      }))
      .filter((el) => {
        const initialNeeded = Number(
          initialValues.find((initialEl) => initialEl.id === el.id)?.needed,
        );
        if (!initialNeeded && !el.needed) return false;
        return true;
      });

    update.mutate(items);
  };

  return {
    form,
    fields,
    resetFields,
    onSubmit,
  };
};
