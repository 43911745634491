/* eslint-disable immutable/no-mutation */

import { useToast } from '@hooks/useToast';
import { Errors, UseErrorsOptions } from './types';
import { useTranslation } from 'react-i18next';

export const useErrors = (options?: UseErrorsOptions) => {
  const { t } = useTranslation('app');
  const { toast } = useToast();

  const toastErrors: string[] = [];
  const formErrors: Record<string, string> = {};

  const translate = (key: string) => t([`errors.${key}`, key]);

  const appendErrors = (data: Errors) => {
    if (typeof data === 'string') {
      toastErrors.push(translate(data));
    } else if (Array.isArray(data)) {
      toastErrors.concat(data.map((el) => translate(el)));
    } else {
      const { base, ...rest } = data;
      if (Array.isArray(base)) toastErrors.concat(base.map((el) => translate(el)));
      else if (typeof base === 'string') toastErrors.push(translate(base));
      const keys = Object.keys(rest);
      keys.forEach((key) => {
        const value = rest[key];
        if (Array.isArray(value)) formErrors[key] = value.map((el) => translate(el)).join('\n');
        else formErrors[key] = translate(value);
      });
    }
  };

  return (data?: Errors, setFormError?: (key: string, value: string) => void) => {
    if (!data) return;

    appendErrors(data);

    if (options) {
      const { allInToast } = options;
      if (allInToast) {
        Object.keys(formErrors).forEach((key) => {
          toastErrors.push(`${key}\n${formErrors[key]}`);
        });
      }
    }

    if (setFormError) {
      Object.keys(formErrors).forEach((key) => {
        setFormError(key, formErrors[key]);
      });
    }

    toastErrors.forEach((description) => {
      toast({
        title: t('error'),
        description,
        variant: 'destructive',
      });
    });
  };
};
