import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { queryKeys } from '@utils/consts';
import { StagesDropdownRespSuccess } from './types';
import { useAppContext } from '@contexts/app';

export const useStagesDropdown = () => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { data, error, isFetching } = useQuery<StagesDropdownRespSuccess, ApiError>({
    queryKey: queryKeys.dropdowns.stages,
    queryFn: () => queryFetch({ url: 'dropdowns/stages' }),
    placeholderData: (previousData) => previousData as StagesDropdownRespSuccess,
    enabled,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
