import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { useQueryParams } from '@hooks/queryParams';
import { SubtasksForFilesDropdownProps, SubtasksForFilesDropdownRespSuccess } from './types';
import { useAppContext } from '@contexts/app';

export const useSubtasksForFilesDropdown = ({ jobId }: SubtasksForFilesDropdownProps) => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { parseParams } = useQueryParams();

  const queryParams = parseParams({ jobId });

  const { data, error, isFetching } = useQuery<SubtasksForFilesDropdownRespSuccess, ApiError>({
    queryKey: queryKeys.dropdowns.subtasksForFiles,
    queryFn: () =>
      queryFetch({
        url: `dropdowns/subtasks_for_files${queryParams}`,
      }),
    enabled: enabled && Boolean(jobId && uuidRegEx.test(jobId)),
    placeholderData: (previousData) => previousData as SubtasksForFilesDropdownRespSuccess,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
