import { customJsonParser, camelToSnakeCase, snakeToCamelCase } from '@utils/textHelpers';
import queryString, { StringifyOptions } from 'query-string';
import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { useSaveFilters } from '@hooks/useSaveFilters';
import { QueryParams, SetParamsOptions } from './types';

export const useQueryParams = () => {
  const options: StringifyOptions = {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  };
  const [state, setState] = useSearchParams();
  const saveFilters = useSaveFilters();

  const params = customJsonParser<QueryParams>(
    queryString.parse(state.toString(), options),
    snakeToCamelCase,
  );

  const parseParams = (paramsToParse: Record<string, unknown>) => {
    const parsed = queryString.stringify(
      customJsonParser(paramsToParse, camelToSnakeCase),
      options,
    );
    if (parsed.length) return `?${parsed}`;
    return parsed;
  };

  const setParams = (newParams: QueryParams, opt?: SetParamsOptions & NavigateOptions) => {
    const mergedParams = { ...params, ...newParams };
    setState(new URLSearchParams(parseParams(mergedParams)), opt);
    if (opt?.disableSaveFilters) return;
    saveFilters(mergedParams);
  };

  const setParam = (key: keyof QueryParams, value: unknown) => {
    setParams({ ...params, [key]: value });
  };

  return {
    params,
    setParam,
    setParams,
    parseParams,
    searchParams: state,
  };
};
