import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { useQueryParams } from '@hooks/queryParams';
import { InventoryDataProps, InventoryLocationsRespSuccess } from '../types';
import { parseCustomItems } from '../helpers';

export const useGetInventoryAvailable = ({ location, customItems }: InventoryDataProps) => {
  const {
    params: { routeId, available: search },
    parseParams,
  } = useQueryParams();

  const { locationId, locationType } = location;

  const queryParams = parseParams({
    routeId,
    search,
    locationId,
    locationType: locationId ? locationType : undefined,
    locationCustomItems: parseCustomItems(customItems),
  });

  const { data, error, isFetching } = useQuery<InventoryLocationsRespSuccess, ApiError>({
    queryKey: [...queryKeys.inventoryAvailable, queryParams],
    queryFn: () =>
      queryFetch({
        url: `routes/available${queryParams}`,
      }),
    placeholderData: (previousData) => previousData as InventoryLocationsRespSuccess,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
