import { addHours, addMinutes, isBefore, parseISO, set } from 'date-fns';
import { sortByDate } from '@pages/hotSeat/utils';
import { compareHours } from '@utils/dateHelpers';
import { Schedule } from '@services/jobs/schedules/teams';
import {
  DIVIDED_HOUR,
  HOURS_COUNT,
  SEGMENT_LENGTH,
  START_HOUR,
} from '@contexts/scheduler/constants';

export const appendHours = (array: unknown[], date: Date) => array.map((_, i) => addHours(date, i));

export const getHours = (date: Date) => appendHours([...new Array<undefined>(HOURS_COUNT)], date);

export const getFirstHour = (date: Date) =>
  set(date, { hours: START_HOUR, minutes: 0, seconds: 0 });

export const getLastHour = (hours: Date[][]) => {
  const lastHour = hours[hours.length - 1];
  return addMinutes(lastHour[lastHour.length - 1], SEGMENT_LENGTH);
};

export const getDividedHours = (hours: Date[]) =>
  hours.map((el) => DIVIDED_HOUR.map((_, i) => addMinutes(el, SEGMENT_LENGTH * i)));

export const groupSchedules = (items: Schedule[]) => {
  const schedules = sortByDate(items, 'startsAt', 'asc');
  let newSchedules: Schedule[][] = [];
  let tmp: Schedule[] = [];
  const usedSchedulesIds: string[] = [];
  let prevEndsAt: Date | undefined;

  schedules.forEach((schedule) => {
    if (usedSchedulesIds.includes(schedule?.id || '')) return;
    const startsAt = parseISO(schedule?.startsAt || '');
    if (!prevEndsAt || isBefore(prevEndsAt, startsAt) || compareHours(prevEndsAt, startsAt)) {
      tmp.push(schedule);
    } else {
      if (tmp.length) newSchedules.push(tmp);
      newSchedules.push([schedule]);
      tmp = [];
    }
    usedSchedulesIds.push(schedule?.id || '');
    prevEndsAt = parseISO(schedule.endsAt);
  });

  if (tmp.length) newSchedules = [...newSchedules, tmp];

  return newSchedules;
};
