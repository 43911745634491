import { InventoryLocationItem } from '@root/globalTypes';
import { customJsonParser, camelToSnakeCase } from '@utils/textHelpers';

export const parseCustomItems = (data?: InventoryLocationItem[]) => {
  if (!data?.length) return undefined;

  return data.map(({ id, globalItemId, amount }) =>
    JSON.stringify(customJsonParser({ id, globalItemId, amount }, camelToSnakeCase)),
  );
};
