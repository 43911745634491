import {
  set,
  parseISO,
  getDate,
  getMinutes,
  setMinutes,
  getMonth,
  getYear,
  isBefore,
  isAfter,
} from 'date-fns';
import { SEGMENT_LENGTH, initialState } from './constants';
import { Job, JobTask } from '@root/globalTypes';
import { Schedule } from '@services/jobs/schedules/teams';
import { getFirstHour, getLastHour } from '@components/scheduler/grid/helpers';

export const createSchedule = (task: JobTask, data?: Job) => ({
  ...initialState.active.schedule,
  id: 'new',
  task: {
    ...initialState.active.schedule.task,
    ...task,
    job: data || initialState.active.schedule.task?.job,
  },
});

export const setDatesForCurrentDay = (date?: Date, startsAt?: string, endsAt?: string) => {
  if (!startsAt || !endsAt) return { startsAt: '', endsAt: '' };
  if (!date) return { startsAt, endsAt };

  return {
    startsAt: set(parseISO(startsAt), {
      date: getDate(date),
      month: getMonth(date),
      year: getYear(date),
    }).toISOString(),
    endsAt: set(parseISO(endsAt), {
      date: getDate(date),
      month: getMonth(date),
      year: getYear(date),
    }).toISOString(),
  };
};

export const roundDate = (date: string | undefined, hours?: Date[][]) => {
  if (!date || !hours) return undefined;

  const parsedDate = parseISO(date);
  const firstHour = getFirstHour(parsedDate);
  const lastHour = getLastHour(hours);
  const minutes = getMinutes(parsedDate);
  const minutesToSegment = minutes % SEGMENT_LENGTH;

  const newDate = setMinutes(
    parsedDate,
    minutesToSegment > 7
      ? minutes + (SEGMENT_LENGTH - minutesToSegment)
      : minutes - minutesToSegment,
  );

  if (isBefore(newDate, firstHour)) return firstHour;
  if (isAfter(newDate, lastHour)) return lastHour;
  return newDate;
};

export const roundSchedulesDates = (data: Schedule[], hours: Date[][]): Schedule[] =>
  data.map((el) => ({
    ...el,
    startsAt: roundDate(el.startsAt, hours)?.toISOString() || '',
    endsAt: roundDate(el.endsAt, hours)?.toISOString() || '',
  }));
