import { InventoryData } from './types';

export const initialState: InventoryData = {
  routeId: '',
  routes: {
    id: '',
    name: '',
    status: 'draft',
    startsAt: '',
    endsAt: '',
    foremanId: '',
    remainingLoad: false,
    scheduleDate: '',
    points: [],
    note: {},
    addresses: [],
    confirmed: false,
    multiload: false,
  },
  draft: true,
  processing: {
    active: false,
    extraDropOffNeeded: false,
    type: 'requested',
    data: {
      locationId: '',
      locationAddress: '',
      locationType: 'job',
    },
  },
  dialog: {
    schedule: {
      open: false,
    },
    confirmation: {
      open: false,
      type: null,
      data: {
        locationId: '',
        locationAddress: '',
        locationType: 'job',
      },
    },
    remove: {
      open: false,
    },
    customAdd: {
      open: false,
    },
  },
};
