import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { useQueryParams } from '@hooks/queryParams';
import { TasksDropdownProps, TasksRespSuccess } from './types';
import { useAppContext } from '@contexts/app';

export const useTasksDropdown = ({ jobId, ...props }: TasksDropdownProps) => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { parseParams } = useQueryParams();

  const queryParams = parseParams({ ...props, jobId });

  const { data, error, isFetching } = useQuery<TasksRespSuccess, ApiError>({
    queryKey: queryKeys.dropdowns.tasks,
    queryFn: () => queryFetch({ url: `dropdowns/tasks${queryParams}` }),
    enabled: enabled && Boolean(jobId && uuidRegEx.test(jobId)),
    placeholderData: (previousData) => previousData as TasksRespSuccess,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
