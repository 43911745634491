import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { queryKeys } from '@utils/consts';
import { ApiError } from '@root/globalTypes';
import { LocationsDropdownRespSuccess } from './types';
import { useAppContext } from '@contexts/app';

export const useLocationsDropdown = () => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { data, error, isFetching } = useQuery<LocationsDropdownRespSuccess, ApiError>({
    queryKey: queryKeys.dropdowns.locations,
    queryFn: () => queryFetch({ url: 'dropdowns/locations' }),
    placeholderData: (previousData) => previousData as LocationsDropdownRespSuccess,
    enabled,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
