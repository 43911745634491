import { Speciality, User } from '@root/globalTypes';

export const modelUserData = (userData: User): User => ({
  firstName: userData?.firstName || '',
  lastName: userData?.lastName || '',
  role: userData?.role,
  isManager: [
    'administrator',
    'superintendent',
    'project_coordinator',
    'general_superintendent',
    'read_only',
  ].includes(userData?.role || ''),
  isForeman: userData?.role === 'foreman',
  isCadTechnician: userData?.role === 'cad_technician',
  speciality: userData?.speciality as Speciality,
  isLogged: true,
  isDropdownEnabled: userData?.role !== 'foreman',
  id: userData?.id || '',
});
