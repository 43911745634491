import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useLocation } from 'react-router-dom';
import { Tab } from '@root/globalTypes';
import { data } from './constants';
import { kebabToCamelCase } from '@utils/textHelpers';
import { useElementMeasures } from '@hooks/useElementMeasures';
import { cn } from '@utils/cn';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { useAppContext } from '@contexts/app';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const Tutorial = () => {
  const { isTutorial, setData } = useAppContext();
  const { pathname } = useLocation();
  const { t } = useTranslation('app');
  const ref = useRef<HTMLDivElement>(null);
  const headerHeight = useElementMeasures('header').height;
  const tab = kebabToCamelCase(pathname.split('/')[1]) as Tab;

  const close = () => setData((prev) => ({ ...prev, isTutorial: false }));

  return (
    <div
      ref={ref}
      className={cn(
        'scrollbar-thin fixed right-0 z-[100] max-w-[800px] overflow-y-auto bg-white shadow-card transition-transform duration-150',
        !isTutorial && 'translate-x-full',
      )}
      style={{ top: headerHeight, maxHeight: `calc(100vh - ${headerHeight}px)` }}>
      <div className="sticky top-0 flex h-10 items-center justify-between border-b bg-white pl-5 pr-2">
        <strong className="text-neutral-800">{t('tutorial')}</strong>
        <button
          className="text-xs text-neutral-300 hover:text-neutral-800"
          onClick={close}
          type="button">
          <SvgConsumer id="close" />
        </button>
      </div>
      <Markdown className="prose prose-sm py-4 pl-5 pr-2" remarkPlugins={[remarkGfm]}>
        {data[tab]}
      </Markdown>
    </div>
  );
};
