import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { queryKeys } from '@utils/consts';
import { ApiError } from '@root/globalTypes';
import { useQueryParams } from '@hooks/queryParams';
import { ProductsDropdownProps, ProductsDropdownRespSuccess } from '../types';
import { useAppContext } from '@contexts/app';

export const useConcreteMixDropdown = ({ externalSiteId }: ProductsDropdownProps) => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { parseParams } = useQueryParams();

  const queryParams = parseParams({ externalSiteId });

  const { data, error, isFetching } = useQuery<ProductsDropdownRespSuccess, ApiError>({
    queryKey: [...queryKeys.dropdowns.concreteMix, externalSiteId],
    queryFn: () => queryFetch({ url: `dropdowns/concrete_direct_concrete_mix${queryParams}` }),
    placeholderData: (previousData) => previousData as ProductsDropdownRespSuccess,
    enabled: enabled && Boolean(externalSiteId),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
