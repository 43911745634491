import { useQueryParams } from '@hooks/queryParams';
import { ApiError } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { queryFetch } from '@services/api';
import { UsersDropdownRespSuccess } from '../types';
import { UsersDropdownProps } from './types';
import { useAppContext } from '@contexts/app';

export const useUsersDropdown = ({ role }: UsersDropdownProps) => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { parseParams } = useQueryParams();

  const queryParams = parseParams({ role });

  const { data, error, isFetching } = useQuery<UsersDropdownRespSuccess, ApiError>({
    queryKey: [...queryKeys.dropdowns.users, queryParams],
    queryFn: () => queryFetch({ url: `dropdowns/users${queryParams}` }),
    placeholderData: (previousData) => previousData as UsersDropdownRespSuccess,
    enabled,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
