import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
  InventoryContextData,
  InventoryData,
  InventoryProcessingData,
  InventoryProviderProps,
  InventoryToggle,
} from './types';
import { initialState } from './constants';
import { InventoryCreateRoutePointReq } from '@services/inventory_new/points/types';
import { useQueryParams } from '@hooks/queryParams';
import { useCreateInventoryRoutePoint } from '@services/inventory_new/points/create';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useGetInventoryRoutes } from '@services/inventory_new/routes';
import { useCreateInventoryRoute } from '@services/inventory_new/routes/create';
import { useRemoveInventoryRoute } from '@services/inventory_new/routes/remove';
import { useClearAllInventoryRoutePoints } from '@services/inventory_new/points/clearAll';
import { useUpdateInventoryRoutePoint } from '@services/inventory_new/points/update';
import { useGetInventoryAvailable } from '@services/inventory_new/available';
import { useGetInventoryRequested } from '@services/inventory_new/requested';
import { useGetInventoryRequestedLocation } from '@services/inventory_new/requested/location';
import { useGetInventoryAvailableLocation } from '@services/inventory_new/available/location';
import { useCreateInventoryRoutePointsPair } from '@services/inventory_new/pair/create';
import { InventoryAdditionalItemsConfirmationDialogOption } from '@components/inventory_new/dialogs/confirmation/additionalItems/options/types';
import { CreateInventoryRoutePointsPairReq } from '@services/inventory_new/pair/create/types';
import { InventoryLocation } from '@root/globalTypes';
import { useRemoveInventoryRoutePointsPair } from '@services/inventory_new/pair/remove';
import { useDropdownsContext } from '@contexts/dropdowns';

const InventoryContext = createContext<InventoryContextData | Record<string, never>>({});

export const InventoryProvider = ({ children }: InventoryProviderProps) => {
  const {
    routes: { options },
  } = useDropdownsContext();

  const {
    params: { routeId },
    setParam,
  } = useQueryParams();

  const { toast } = useToast();
  const { t } = useTranslation('app', { keyPrefix: 'messages.success.inventory' });
  const [state, setState] = useState<InventoryData>(initialState);

  const {
    dialog: {
      confirmation,
      customAdd: { data: customItems, unpackDelivery },
    },
    processing: {
      type,
      data: { locationId, locationType },
      extraDropOffNeeded,
    },
  } = state;

  const endProcess = (key: string) => {
    toast({ title: t(key) });
    setState((prev) => ({
      ...prev,
      processing: initialState.processing,
      dialog: initialState.dialog,
    }));
  };

  const requested = useGetInventoryRequested({ location: state.processing.data, customItems });
  const requestedLocation = useGetInventoryRequestedLocation({
    preview: { type, locationId, locationType },
    customItems,
  });

  const available = useGetInventoryAvailable({ location: state.processing.data, customItems });
  const availableLocation = useGetInventoryAvailableLocation({
    preview: { type, locationId, locationType },
    customItems,
  });

  const routesResp = useGetInventoryRoutes();
  const routes = routesResp.data?.data;

  const createRoute = useCreateInventoryRoute({
    callback: () => endProcess('routes.create'),
  });

  const removeRoute = useRemoveInventoryRoute({
    callback: () => endProcess('routes.remove'),
  });

  const createPoint = useCreateInventoryRoutePoint({
    location: state.processing.data,
    callback: () => endProcess('create'),
  });

  const updatePoint = useUpdateInventoryRoutePoint({
    location: state.processing.data,
    callback: () => endProcess('update'),
  });

  const clearAllPoints = useClearAllInventoryRoutePoints({
    location: state.processing.data,
    callback: () => endProcess('clearAll'),
  });

  const createPair = useCreateInventoryRoutePointsPair({
    location: state.processing.data,
    callback: () => endProcess('pair.create'),
  });

  const removePair = useRemoveInventoryRoutePointsPair({
    location: state.processing.data,
    callback: () => endProcess('pair.remove'),
  });

  const toggle = (prop: InventoryToggle, open: boolean) =>
    setState((prev) => ({
      ...prev,
      dialog: { ...prev.dialog, [prop]: { ...prev.dialog[prop], open } },
    }));

  const handleCreateExtraDropOff = (location: InventoryLocation) => {
    if (!routeId) return;
    const values: InventoryCreateRoutePointReq = {
      predefined: true,
      predefinedType: 'extra_drop',
      deliveryType: 'drop_off',
      locationId: location.locationId,
      locationType: location.locationType,
      routeId,
    };
    createPoint.mutate(values);
  };

  const handleCreatePair = (location: InventoryLocation) => {
    const values: CreateInventoryRoutePointsPairReq = {
      dropOffLocationId: type === 'requested' ? locationId : location.locationId,
      dropOffLocationType: type === 'requested' ? locationType : location.locationType,
      pickUpLocationId: type === 'requested' ? location.locationId : locationId,
      pickUpLocationType: type === 'requested' ? location.locationType : locationType,
      skipExcess: confirmation.value === 'skipExcess',
      includeAllInCurrentDrop: confirmation.value === 'includeAllInCurrentDrop',
      createExtraDrop: confirmation.value === 'createExtraDrop',
      dropOffCustomItems: type === 'requested' ? customItems : undefined,
      pickUpCustomItems: type === 'available' ? customItems : undefined,
      unpackDelivery,
    };
    createPair.mutate(values);
  };

  const handleCreate = (location: InventoryLocation) => {
    if (extraDropOffNeeded) return handleCreateExtraDropOff(location);
    return handleCreatePair(location);
  };

  const handleClose = () => {
    if (extraDropOffNeeded) toggle('remove', true);
    else
      setState((prev) => ({
        ...prev,
        processing: initialState.processing,
        dialog: { ...prev.dialog, customAdd: initialState.dialog.customAdd },
      }));
  };

  const setProcessing = useCallback((): InventoryProcessingData => {
    const point = routes?.points.flat().find((el) => el.hidden && el.deliveryType === 'pick_up');
    const data = available?.data?.records.find(
      (el) => el.locationId === point?.job?.id || point?.warehouse?.id,
    );

    if (!point || !data) return state.processing;

    return {
      active: true,
      type: 'available',
      data,
      extraDropOffNeeded: true,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes?.points, available?.data?.records]);

  const closeConfirmationDialog = () => {
    setState((prev) => ({
      ...prev,
      dialog: { ...prev.dialog, confirmation: initialState.dialog.confirmation },
    }));
  };

  const setConfirmationDialogValue = (value: InventoryAdditionalItemsConfirmationDialogOption) => {
    setState((prev) => ({
      ...prev,
      dialog: {
        ...prev.dialog,
        confirmation: {
          ...prev.dialog.confirmation,
          value,
        },
      },
    }));
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, routeId: routeId || '', processing: initialState.processing }));
  }, [routeId]);

  useEffect(() => {
    setState((prev) => ({ ...prev, processing: setProcessing() }));
  }, [setProcessing]);

  useEffect(() => {
    if (!routes) return;
    setState((prev) => ({ ...prev, routes, draft: Boolean(!routes.scheduleDate) }));
  }, [routes]);

  useEffect(() => {
    if (!routeId) return;
    if (!options.some((el) => el.id === routeId)) setParam('routeId', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, routeId]);

  return (
    <InventoryContext.Provider
      value={{
        ...state,
        setState,
        toggle,
        createRoute,
        removeRoute,
        updatePoint,
        clearAllPoints,
        removePair,
        create: {
          mutate: handleCreate,
          isLoading: createPoint.isLoading || createPair.isLoading,
        },
        handleClose,
        setConfirmationDialogValue,
        closeConfirmationDialog,
        requested: {
          isLoading: requested.isLoading,
          data: requested.data?.records || [],
        },
        available: {
          isLoading: available.isLoading,
          data: available.data?.records || [],
        },
        items: {
          isLoading: requestedLocation.isLoading || availableLocation.isLoading,
          data: requestedLocation.data?.records || availableLocation.data?.records || [],
          multiload:
            requestedLocation.data?.multiload || availableLocation.data?.multiload || false,
        },
      }}>
      {children}
    </InventoryContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useInventoryContext = () => useContext(InventoryContext);
