import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useShowErrorToast } from '@utils/formHelpers';
import { DefaultApiResp } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { useQueryParams } from '@hooks/queryParams';

export const useRemoveInventoryRoute = ({
  callback,
}: {
  callback: (data: DefaultApiResp) => void;
}) => {
  const { setParam } = useQueryParams();
  const queryClient = useQueryClient();
  const { showErrorToast } = useShowErrorToast();
  const { mutate, isPending } = useMutation({
    mutationFn: (id?: string): Promise<DefaultApiResp> =>
      queryFetch({
        url: `routes_planner/${id}`,
        opt: { method: 'DELETE' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      setParam('routeId', undefined);
      await queryClient.refetchQueries({ queryKey: queryKeys.dropdowns.routes });
    },
    onSettled: (data) => {
      const { success, errors } = data as DefaultApiResp;
      if (errors) showErrorToast(errors);
      if (success) callback(data as DefaultApiResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
