import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { ApiError } from '@root/globalTypes';
import { useQueryParams } from '@hooks/queryParams';
import { UsersDropdownRespSuccess } from '../types';
import { BuilderSuperintendentsDropdownProps } from './types';
import { useAppContext } from '@contexts/app';

export const useBuilderSuperintendentsDropdown = ({
  builderId,
}: BuilderSuperintendentsDropdownProps) => {
  const { isDropdownEnabled: enabled } = useAppContext();

  const { parseParams } = useQueryParams();

  const queryParams = parseParams({ builderId });

  const { data, error, isFetching } = useQuery<UsersDropdownRespSuccess, ApiError>({
    queryKey: [...queryKeys.dropdowns.builderSuperintendents, queryParams],
    queryFn: () => queryFetch({ url: `dropdowns/builder_superintendents${queryParams}` }),
    placeholderData: (previousData) => previousData as UsersDropdownRespSuccess,
    enabled: enabled && Boolean(builderId && uuidRegEx.test(builderId)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
