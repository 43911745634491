import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useShowErrorToast } from '@utils/formHelpers';
import { DefaultApiResp, InventoryLocation } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { UpdateInventoryRoutePointReq } from './types';
import { useQueryParams } from '@hooks/queryParams';

export const useUpdateInventoryRoutePoint = ({
  location,
  callback,
}: {
  location: InventoryLocation;
  callback: (data: DefaultApiResp) => void;
}) => {
  const {
    params: { routeId },
    parseParams,
  } = useQueryParams();

  const queryParams = parseParams({ routeId, ...location });

  const queryClient = useQueryClient();
  const { showErrorToast } = useShowErrorToast();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, ...data }: UpdateInventoryRoutePointReq): Promise<DefaultApiResp> =>
      queryFetch({
        url: `routes/points/${id}`,
        data,
        opt: { method: 'PUT' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({
        queryKey: [...queryKeys.inventoryAvailable, queryParams],
      });
      await queryClient.refetchQueries({
        queryKey: [...queryKeys.inventoryRequested, queryParams],
      });
      await queryClient.refetchQueries({ queryKey: [...queryKeys.inventoryRoutes, routeId] });
      await queryClient.refetchQueries({ queryKey: queryKeys.dropdowns.routes });
    },
    onSettled: (data) => {
      const { success, errors } = data as DefaultApiResp;
      if (errors) showErrorToast(errors);
      if (success) callback(data as DefaultApiResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
